.NavbarItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    width: 100%;
    height: 80px;
    z-index: 9999;
    position: absolute;
}

.navbar-logo{
    cursor: pointer;
}

.nav-menu-con{
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap: 0px;
    list-style: none;
    align-items: center;
}



.dropdown-menu-dark {
    --bs-border-color: transparent;
    --bs-border-color-translucent: transparent;
    --bs-dropdown-link-hover-color: black;
}



.dropdown-menu-dark {
    --bs-dropdown-color: #474848;
    --bs-dropdown-bg: transparent;
    /* --bs-dropdown-border-color: var(--bs-border-color-translucent); */
    --bs-dropdown-box-shadow: ;
    --bs-dropdown-link-color: #474848
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    /* --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15); */
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #0d6efd;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-header-color: #adb5bd;
}

.menu-icons{
    display: none;
}

@media screen and (max-width: 850px){
    .NavbarItems{
        z-index: 99;
    }
    

    .nav-logo{
        width:55%

    }

    .nav-logo2{
        width:55%;
        float: right;
    }

    .navbar-logo{
        cursor: pointer;

    }

    .menu-icons{
        display: block;
        cursor: pointer;
    }

    .menu-icons i{
        font-size: 1.2rem;
    }


}