@keyframes scroll {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
  
.slide{
    display: flex;
    align-items: center;
    min-height: fit-content;
    justify-content: center;
    background-color: #e7f1ff;
}

.aisehi{
  text-align: left;
  margin-left: 15%;
}

.side-line{
    font-size: x-large;
    width: 37%;
    /* position: absolute; */
    z-index: 99;
    background-color: #e7f1ff;
    /* height: 100%; */
    text-align: right;
    padding-right: 3%;
}

.side-text{
    font-size: 150%;
    font-weight: bold;
}

.inslider{
    margin-left:3% ;
    width:100%;
    height:7rem; 
    border-top: 1px; 
    border-bottom: 1px; 
    border-color: gray; 
    overflow:hidden; 
    position:relative;
    padding-top: 3px;
    padding-bottom: 3px;
}

.actualSlider{
    width:200%;
    display:flex; 
    align-items: center; 
    height:7rem;
    justify-content: space-around;
    position:absolute;
    left:0; 
    gap:5rem;
}

.logos{
    display:flex; 
    justify-content: center; 
    /* align-items: start;  */
    width: 20rem;
}

.animate {
    animation: scroll 15s linear infinite;
}

@media screen and (max-width:500px) {

  .side-line{
    width: 65%;
    position: absolute;
    z-index: 99;
    background-color: transparent;
    height: 1%;
    text-align: left;
    padding-right: 0%;
    
}

  .side-text{
    
    position: relative;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    top: -50px;
    /* left: 0px; */
}
.logos{
  display:flex; 
  justify-content: center; 
  width: 10rem;
}

.aisehi{
  text-align: left;
  /* margin-left: 15%; */
}

.actualSlider{
  width:200%;
  display:flex; 
  align-items: center; 
  height:9rem;
  justify-content: space-around;
  position:absolute;
  left:0; 
  gap:2rem;
}
}

@media screen and (max-width:400px) {
  .side-line{
    width: 65%;
    position: absolute;
    z-index: 99;
    background-color: transparent;
    height: 1%;
    text-align: left;
    padding-right: 0%;

}

.side-text{
    
  position: relative;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  top: -50px;
  /* left: 20px; */
}
}