.hero{
    width: 100%;
    height: 100%;
    position: relative;
}

.Line2{
    position: absolute;
    /* text-align: right; */
    padding-left: 175px;
}

.hero-img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    background-color: #F4F6FB;
}

.con-hero-text{
    position: absolute;
    top: 37%;
    text-align: center;
    /* transform: translate(-50%, -50%); */
    width: 100%;
}
.con-hero-text h1 s{
    font-size: 6rem;
    font-weight: 800;
    background: #1A6659;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.con-hero-text h1{
    font-size: 5rem;
    font-weight: 800;
    width: fit-content;
    margin: auto;
    background: #0F0F0F;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}




@media screen and (max-width:600px) {
    .hero-text h1{
        /* padding: 10px 20px; */
        font-size: 2rem;
    }
    .con-hero-text{
        padding-top: 8%;
        position: absolute;
        top: 37%;
        text-align: center;
        /* transform: translate(-50%, -50%); */
        width: 100%;
        font-size: 2rem;
    }

    .con-hero-text h1{
        font-size: 2rem;
        font-weight: 800;
        width: fit-content;
        margin: auto;
        background: #0F0F0F;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .con-hero-text h1 s{
        font-size: 2rem;
        font-weight: 800;
        background: #1A6659;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .Line2{
        display: none;
        
    }

    .hero-img{
        width: 100%;
        height: fit-content;
        object-fit: cover;
        background-color: #F4F6FB;
    }

}

@media screen and (min-width:600px) and (max-width:950px){
    .con-hero-text h1{
        /* padding: 10px 20px; */
        font-size: 3rem;
    }

    .Line2{
        display: none;
        
    }
}

