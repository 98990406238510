.plane3 {
  /* margin-top: 200px; */
  margin: auto;
  width: 97%;
  height: fit-content;
  /* transform: translate(10%); */
  padding-top: 5%;
}

.card-body-test {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: #7a7a7a;
  height: fit-content;
  padding-bottom: 5%;
  border-top: solid;
  border-top-width: 10px;
  border-radius: 10px;
  border-top-color: #ff0000;
}

.name {
  font-size: 25px;
  font-weight: bold;
  height: 200px;
}

.card-group > .card + .card {
  margin-left: 0;
  border-left: solid;
  border-width: 0.5px;
  border-color: rgb(0 0 0 / 18%);
  border-top: 0;
  /* border-radius: 10px; */
}

.card-group > .card:not(:last-child) {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top: 0;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0px;
  border-top-width: 1px;
}

.hel1 {
  margin-top: 5%;
}

.cdgrp {
  margin-left: 0%;
  margin-top: 3%;
  margin-right: 0%;
  padding-bottom: 5%;
}

.crdtxt {
  margin: 5% 5%;
  font-size: 20px;
}

@media screen and (max-width: 1200px) {
  /* .myth {
    display: none;
  } */
}

@media screen and (max-width: 500px) {
  /* .m.yth {
    display: none;
  } */

  .hel1 {
    font-size: 300%;
  }

  .crdtxt {
    margin: 5% 5%;
    font-size: small;
    font-weight: bold;
  }

  .name {
    font-size: 15px;
    font-weight: bold;
    height: fit-content;
  }
}
