.g-4-Rev {
  width: 85%;
  margin: auto;
  padding-top: 10%;
}

.LL1 {
  float: right;
  margin-top: 1%;
  width: 20%;
}

.con-rev-p {
  margin-top: 10%;
  font-weight: 400;
  font-size: 20px;
  color: #7a7a7a;
}

.h3-con {
  margin-top: 10px;
  font-weight: 800;
}

.con-rev-div {
  font-weight: 400;
  font-size: 20px;
  color: #7a7a7a;
}

s {
  font-size: 6rem;
  font-weight: 800;
  background: #1a6659;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.value {
  margin: auto;
}

.tri2 {
  position: absolute;
  z-index: 99;
  float: right;
}

.con-ETV-fullstop {
  font-size: 110%;
}

.con-ETV {
  font-size: 250%;
  font-weight: bold;
  line-height: 0.7;
}

em {
  color: #ff0101;
}

.RevleftCol-Rev p {
  margin-top: 10%;
  font-weight: 400;
  font-size: x-large;
}

.con-RevleftCol-Rev {
  background-color: #f4f6fb;
  display: flex;
  justify-items: center;
}

.RevrightCol-Rev {
  height: auto;
  padding-left: 10%;
}

.RevleftCol-Rev {
  padding-bottom: 5%;
}

.con-bttn {
  float: left;
  margin-top: 10%;
}

.btn-primary {
  --bs-btn-border-color: #b8b8b8;
  --bs-btn-bg: #ffffff;
  --bs-btn-color: #141619;
  --bs-btn-border-radius: 35px;
}
/* span {
  width: 70%;
  float: left;
  margin-top: 2.5%;
} */

pre {
  float: right;
  margin-top: 1%;
  transform: rotate(270deg);
}

pre img {
  height: 75%;
  width: 75%;
}

.con-txt-btn {
  width: 70%;
  float: left;
  margin-top: 4.5%;
  color: #9a9999;
}

@media screen and (max-width: 500px) {
  .LL1 {
    width: 15%;
  }
}

@media screen and (max-width: 768px) {
  .RevrightCol-Rev {
    width: 100%;
    height: auto;
    padding-left: 4%;
  }

  .xx {
    margin-top: 15%;
  }

  .con-ETV {
    font-size: 200%;
    font-weight: bold;
    line-height: 0.7;
  }
  .bttn {
    width: 80%;
  }

  .RevleftCol-Rev {
    margin-top: 70px;
    padding-bottom: 5%;
  }

  /* span {
    width: 50%;
    float: left;
    margin-top: 2.5%;
    font-size: small;
  } */
  .accordion-header span {
    font-size: 26px;
  }
  .tri2 {
    /* display:none; */
  }
}

@media screen and (max-width: 1800px) {
  .tri2 {
    /* display:none; */
  }
}
