.g-4-Ano {
  margin-top: 10%;
  width: 85%;
  /* transform: translate(7.5%); */
  margin: auto;
  padding-top: 10%;
}

h1 {
  font-size: 400%;
  font-weight: bold;
}

em {
  color: #ff0101;
}

.leftCol-Ano p {
  margin-top: 10%;
  font-weight: 400;
  font-size: 20px;
  color: #7a7a7a;
}

.rightCol-Ano {
  /* width: 50%; */
  transform: translate(0%);
  height: auto;
}

.bttn {
  float: left;
  width: 300px;
  margin-bottom: 50px;
}

.btn-primary {
  --bs-btn-border-color: #b8b8b8;
  --bs-btn-bg: #ffffff;
  --bs-btn-color: #141619;
  --bs-btn-border-radius: 28px;
}
/* span{
    width: 70%;
    float: left;
    margin-top: 2.5%;
} */

.ano-txt-btn {
  width: 70%;
  float: left;
  margin-top: 4.5%;
}

pre {
  float: right;
  margin-top: 1%;
  transform: rotate(270deg);
}

pre img {
  height: 75%;
  width: 75%;
}

.Log {
  position: fixed;
  padding: 16%;
  margin-top: 20%;
}

@media screen and (max-width: 768px) {
  .rightCol {
    width: 100%;
    height: auto;
  }
  .bttn {
    width: 80%;
  }

  .ano-txt-btn {
    width: 50%;
    float: left;
    margin-top: 2.5%;
    font-size: small;
  }

  .Esys {
    font-size: 300%;
    padding-top: 25px;
  }

  .leftCol-Ano p {
    margin-top: 10%;
    font-weight: 400;
    font-size: medium;
  }

  /* span{
        width: 50%;
        float: left;
        margin-top: 2.5%;
        font-size: small;
    } */
  .rightCol-Ano {
    margin-top: 30px;
  }
}
