@media screen and (min-width: 850px){
    .navbar-expand-sm .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        transform: none!important;
        transition: none;
    }
    }