.g-4-twocol {
  margin-top: 10%;
  width: 85%;
  /* transform: translate(7.5%); */
  margin: auto;
  padding-top: 10%;
}

h1 {
  font-size: 400%;
  font-weight: bold;
}

em {
  color: #ff0101;
}

.leftCol-twocol p {
  margin-top: 10%;
  font-weight: 400;
  font-size: 16px;
  color: #7a7a7a;
}

.rightCol-twocol {
  height: auto;
}

.bttn {
  float: left;
  width: 300px;
  margin-bottom: 50px;
}

.xyz {
  transform: rotate(270deg);
  width: 50px;
  text-align: right;
  position: relative;
  margin-left: 10%;
}

.qwerty {
  margin-bottom: 20px;
  --bs-btn-border-radius: 35px;
  --bs-btn-border-color: #b8b8b8;
  --bs-btn-bg: #ffffff;
  --bs-btn-color: #141619;
  min-width: 300px;
}

.qwertz {
  margin-bottom: 20px;
  --bs-btn-border-radius: 35px;
  --bs-btn-border-color: #b8b8b8;
  --bs-btn-bg: #ffffff;
  --bs-btn-color: #141619;
  min-width: 200px;
}

@media screen and (min-width: 385px) and (max-width: 769px) {
  .rightCol {
    width: 100%;
    height: auto;
  }

  .card-title {
    margin-bottom: var(--bs-card-title-spacer-y);
    font-size: 1.5rem;
  }

  .leftCol-twocol p {
    margin-top: 10%;
    font-weight: 400;
    font-size: 16px;
  }

  .rightCol-twocol img {
    margin-top: 20px;
  }
  #insights {
    flex-direction: column-reverse;
  }
}
