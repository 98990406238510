.Planning {
  width: 100%;
  height: fit-content;
  padding: 4% 8%;
  background-color: #f4f6fb;
  margin-top: 7%;
}

.plans {
  font-weight: bold;
}

.image-div {
  margin-top: 10px;
  margin-bottom: 10px;
}

.left-div {
  margin: 10px 0px;
  padding-left: 2%;
  margin-left: 8%;
}

.right-div {
  margin: 10px 0px;
  padding-left: 12%;
}

.image-div {
  display: flex;
  justify-items: center;
}

.con-rev-div {
}

@media screen and (max-width: 800px) {
  .left-div {
    margin: 10px 0px;
    padding-left: 12%;
    /* margin-left: 8%; */
  }
}
