.accordion {
  margin-top: 50px;
  width: 85%;
  transform: translate(7.5%);
}

.accordion-header {
  margin-bottom: 40px;
}

.accordion-header span {
  font-size: 3rem;
}

.aimg {
  width: 75%;
  height: auto;
}

.HeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.CardContainer {
  display: flex;
}

.h1 {
  color: black;
}

.badge {
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: #ff0101;
  border-radius: 100%;
}

.h3 {
  align-items: center;
  margin-top: 5px;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  font-size: 2.5rem;
}

.cText {
  margin-top: 4%;
}

em {
  color: #ff0101;
}

span {
  font-weight: bold;
}

.abody {
  font-weight: bold;
  background-color: #e7f1ff;
  color: #7a7a7a;
  padding-left: 40px;
  padding-top: 30px;
}

.Ahead {
  margin-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  height: 415px;
  padding-left: 30px;
}

.accordion {
  --bs-accordion-btn-icon: url("../../images/Group 469 (1).svg");
  --bs-accordion-btn-active-icon: url("../../images/Group 469.svg");
  --bs-accordion-btn-icon-width: 3rem;
}

@media screen and (max-width: 500px) {
  .card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
    height: fit-content;
  }

  .card-title {
    margin-bottom: var(--bs-card-title-spacer-y);
    font-size: 1.5rem;
  }
}
