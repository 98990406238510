.g-4 {
  width: 85%;
  margin: auto;
  padding-top: 10%;
}

.RevleftCol {
  display: flex;
  justify-content: center;
}

.phone {
  margin-top: 10%;
  font-weight: 400;
  font-size: 20px;
  color: #7a7a7a;
}

h1 {
  font-size: 400%;
  font-weight: bold;
}

em {
  color: #ff0101;
}

.RevleftCol p {
  margin-top: 10%;
  font-weight: 400;
  font-size: x-large;
}

.RevrightCol {
  height: auto;
}

.Revbttn {
  float: left;
  width: 300px;
}

.txt-btn-kompd {
  width: 70%;
  float: left;
  margin-top: 6%;
  font-size: 15px;
}

.btn-primary {
  --bs-btn-border-color: #b8b8b8;
  --bs-btn-bg: #ffffff;
  --bs-btn-color: #141619;
  --bs-btn-border-radius: 35px;
}
/* span{
    width: 70%;
    float: left;
    margin-top: 2.5%;
} */

pre {
  float: right;
  margin-top: 1%;
  transform: rotate(270deg);
}

pre img {
  height: 75%;
  width: 75%;
}

@media screen and (max-width: 769px) {
  .RevrightCol {
    width: 100%;
    height: auto;
  }

  .Kampd {
    font-size: 300%;
  }

  .txt-btn-kompd {
    width: 67%;
    float: left;
    margin-top: 4.5%;
    font-size: 11px;
  }

  .RevleftCol {
    width: 75%;
    height: auto;
    margin: auto;
  }
  .bttn {
    width: 80%;
  }

  pre img {
    height: 50%;
    width: 50%;
  }

  .phone {
    margin-top: 10%;
    font-weight: 400;
    font-size: 20px;
    color: #7a7a7a;
  }

  /* span{
        width: 50%;
        float: left;
        margin-top: 2.5%;
        font-size: small;
    } */
}
