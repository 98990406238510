.plane2 {
  margin-top: 200px;
  width: 100%;
  height: fit-content;
  background-color: #1d1c23;
  /* transform: translate(10%); */
}

.community {
  width: 25%;
  font-size: large;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-left: 1px;
  padding-right: 1px;
  height: 100px;
}

.mbtn {
  margin-top: 5%;
}

.pill {
  background-color: #585858;
  position: absolute;
  bottom: 0;
  padding: 5px 15px;
  border-radius: 30px;
}

.hel {
  margin-top: 1%;
  color: white;
}

.hel2 {
  margin-top: 30px;
  width: 61%;
  color: white;
}

.cdgrp {
  margin-left: 5%;
  margin-top: 6%;
  margin-right: 5%;
}

.management-bttn {
  /* margin-top: 5%; */
  align-items: center;
  width: 100%;
  position: relative;
  padding-bottom: 5%;
}

.manage-btn {
  border-color: #fff !important;
  background: transparent !important;
  color: white !important;
  border-radius: 35px !important;
  width: 30%;
  margin: auto;
}

@media screen and (min-width: 838px) and (max-width: 1117px) {
  .manage-btn {
    border-color: #fff !important;
    background: transparent !important;
    color: white !important;
    border-radius: 35px !important;
    width: 40%;
    margin: auto;
  }
}

@media screen and (min-width: 600px) and (max-width: 838px) {
  .manage-btn {
    border-color: #fff !important;
    background: transparent !important;
    color: white !important;
    border-radius: 35px !important;
    width: 45%;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .hel {
    margin-top: 1%;
    color: white;
    font-size: 300%;
    text-align: center;
  }

  .manage-btn {
    border-color: #fff !important;
    background: transparent !important;
    color: white !important;
    border-radius: 35px !important;
    width: 70%;
    margin: auto;
  }

  .management-bttn {
    align-items: center;
    width: 100%;
    position: relative;
    padding-bottom: 5%;
  }

  .mbtn {
    width: 69%;
    float: left;
    margin-top: 6%;
    font-size: 11px;
  }

  .hel2 {
    margin-top: 30px;
    width: 80%;
    color: white;
    font-size: medium;
  }

  .community {
    width: 75%;
    font-size: large;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    position: relative;
    padding-left: 1px;
    padding-right: 1px;
    height: 100px;
  }
  .plane2 {
    margin-top: 50px;
  }
}
