.plane{
    margin-top: 100px;
    width: 100%;
    height: 800px;
    background-color: #F4F6FB;
    transform: translate(0%);
}

.f1{
    position: absolute;
    top: 17%;
    left: 17%;
}

.f2{
    position: absolute;
    top: 9%;
    left: 60%;
}
.f3{
    position: absolute;
    top: 15%;
    left: 75%;
}
.f4{
    position: absolute;
    top: 68%;
    left: 82%;
    z-index: 99;
}
.f5{
    position: absolute;
    top: 50%;
    left: 85%;
}
.f6{
    position: absolute;
    top: 70%;
    left: 6%;
    z-index: 99;
}
.f7{
    position: absolute;
    top: 35%;
    left: 10%;
    z-index: 99;
}
.f8{
    position: absolute;
    top: 80%;
    left: 23%;
    z-index: 99;
}
.f9{
    position: absolute;
    top: 80%;
    left: 70%;
    z-index: 99;
}
.f10{
    position: absolute;
    top: 12%;
    left: 42%;
    z-index: 99;
}
.f11{
    position: absolute;
    top: 55%;
    left: 12%;
    z-index: 99;
}

.h1-box{
    position:relative;
    
}

.box{
    margin-top: 50%;
    margin: auto;
    width: 100%;
    text-align: center;
}

.center-box{
    display: flex;
    justify-content: center;
    margin: auto;
    width: 60%;
    text-align: center;
    height: 800px;
}

.box2{
    margin-top: 5px;
    /* font-weight: bold; */
    font-size:x-large;
    color: #7A7A7A;
}

.Anabttn{
    align-items: center;
    width: 100%;
}

.abtn{
    margin: auto;
    z-index: 99999;
    width: 500px;
    position: relative;
}

.clip{
    position: fixed;
    bottom: 0;
    width: 100%;
}

@media screen and (min-width:600px) and (max-width:800px) {
    .abtn{
        margin: auto;
        z-index: 99999;
        width: 350px;
        position: relative;
    }
}



@media screen and (max-width:600px) {
    .abtn{
        margin: auto;
        z-index: 99999;
        width: 100%;
        position: relative;
    }

}

@media screen and (max-width:500px) {
    .box{
        margin-top: 50px;
        width: 100%;
        text-align: center;
        font-size: 80%;
    }
    .box2{
        margin-top: 30px;
        /* font-weight: bold; */
        font-size:large;
        color: #7A7A7A;
    }

    .qtxt{
        width: 65%;
    float: left;
    margin-top: 8.5%;
    font-size: 11px;
    }

    .abtn{
        margin: auto;
        z-index: 9;
        width: 250px;
        position: relative;
    }

    .center-box {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 80%;
        text-align: center;
        height: 800px;
    }

    .plane {
        margin-top: 100px;
        width: 100%;
        height: 600px;
        background-color: #F4F6FB;
        transform: translate(0%);
    }
}

@media screen and (max-width:1000px) {
    .myths{
        display: none;
    }
    
}