.ContactbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  /* position: fixed; */
  width: 80%;
  height: fit-content;
  margin: auto;
  /* z-index: 9999; */
  /* position: absolute; */
  background-color: #2a2e34;
  border-radius: 20px;
  cursor: pointer;
}

.cbar {
  width: 100%;
  position: absolute;
  z-index: 99999;
  margin-top: -55px;
  height: fit-content;
}

.ctext {
  font-weight: bold;
}

@media screen and (min-width: 601px) and (max-width: 769px) {
  .cbar {
    width: 100%;
    position: absolute;
    z-index: 99999;
    margin-top: 147px;
    height: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .mail {
    position: absolute;
    top: -30%;
  }

  .xxctext {
    margin-top: 10%;
  }
}
