.g-4-twocol {
  margin-top: 10%;
  width: 85%;
  /* transform: translate(7.5%); */
  margin: auto;
  padding-top: 10%;
}

.con-sys-fullstop {
  font-size: 110%;
}

em {
  color: #ff0101;
}

.leftCol-twocol p {
  margin-top: 10%;
  font-weight: 400;
  font-size: 16px;
  color: #7a7a7a;
}

.leftCol-twocol p {
  margin-top: 10%;
  font-weight: 400;
  font-size: 16px;
  color: #7a7a7a;
}

.con-twoCol {
  margin: auto;
}

.con-rightCol-twocol {
  height: auto;
  background-color: #f4f6fb;
  display: flex;
  justify-items: center;
}

.bttn {
  float: left;
  width: 300px;
  margin-bottom: 50px;
}

.btn-primary {
  --bs-btn-border-color: #b8b8b8;
  --bs-btn-bg: #ffffff;
  --bs-btn-color: #141619;
  --bs-btn-border-radius: 28px;
}
/* span{
    width: 70%;
    float: left;
    margin-top: 2.5%;
} */

pre {
  float: right;
  margin-top: 1%;
  transform: rotate(270deg);
}

.con-sys-para {
  padding-right: 20%;
}

pre img {
  height: 75%;
  width: 75%;
}

.con-sys {
  line-height: 0.7;
  font-size: 250%;
}

@media screen and (max-width: 768px) {
  .rightCol {
    width: 100%;
    height: auto;
  }
  .bttn {
    width: 80%;
  }

  .con-sys {
    line-height: 0.7;
    font-size: 200%;
  }

  .card-title {
    margin-bottom: var(--bs-card-title-spacer-y);
    font-size: 1.5rem;
  }

  .leftCol-twocol p {
    margin-top: 10%;
    font-weight: 400;
    font-size: 16px;
  }

  /* span{
        width: 50%;
        float: left;
        margin-top: 2.5%;
        font-size: small;
    } */

  .con-twoCol {
    margin: auto;
  }
  .rightCol-twocol img {
    margin-top: 20px;
  }
}
