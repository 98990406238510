@import url("https://p.typekit.net/p.css?s=1&k=mkl1dpk&ht=tk&f=139.140.175.176.143.144.147.148.156.157.161.162&a=19802745&app=typekit&e=css");

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:"proxima-nova";
}

.App {
  text-align: center;
}
