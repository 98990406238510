.hero{
    width: 100%;
    height: 100%;
    position: relative;
}

.Line{
    position: absolute;
    top: 54%;
    right: 49%;
    text-align: left;
    /* width: 15%; */
}

.hero-img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    background-color: #F4F6FB;
}

.hero-text{
    position: absolute;
    top: 37%;
    text-align: center;
    /* transform: translate(-50%, -50%); */
    width: 100%;
}
.hero-text h1 div{
    font-size: 6rem;
    font-weight: 800;
    background: #FF0101;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fullstop{
    color: #FF0101;
}

.hero-text h1{
    font-size: 5rem;
    font-weight: 800;
    background: #0F0F0F;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
}




@media screen and (max-width:600px) {
    .hero-text h1{
        /* padding: 10px 20px; */
        font-size: 2rem;
    }
    .hero-text{
        padding-top: 8%;
        position: absolute;
        top: 37%;
        text-align: center;
        /* transform: translate(-50%, -50%); */
        width: 100%;
        font-size: 2rem;
    }

    .hero-text h1 div{
        font-size: 2rem;
        font-weight: 800;
        background: #FF0101;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .Line{
        display: none;
        
    }

    .hero-img{
        width: 100%;
        height: fit-content;
        object-fit: cover;
        background-color: #F4F6FB;
    }

}

