.plane5 {
  width: 100%;
  /* height: 800px; */
  background-color: #1d1c23;
  /* transform: translate(10%); */
  position: absolute;
}

.float-right {
  float: right;
}

.UlFoot {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  text-align: center;
}

.FooterNav {
  /* position: relative;
  bottom: -200px; */
}

.copyright {
  color: white;
  /* position: relative; */
  /* bottom: -250px; */

  margin-right: 9%;
}

.logocopy {
  /* position: relative; */
  /* bottom: -250px; */
  margin-left: 9%;
}

.fooHr {
  /* position: relative;
  bottom: -200px; */
  color: white;
  width: 82%;
  margin: auto;
}

.foot {
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 0.7rem 1rem;
  white-space: nowrap;
  cursor: pointer;
}

.learn {
  margin-left: 5%;
  font-size: 250%;
}

.ConUS {
  margin: auto;
  font-size: larger;
  zoom: 120%;
}

.box-footer {
  margin-left: 5%;
  padding-top: 3%;
  margin-top: 50%;
  margin: auto;
}

.con-center-box-footer {
  border-radius: 30px;
  margin: auto auto;
  width: 80%;
  height: fit-content;
  text-align: left;
  background-color: #1a6659;
  color: white;
  margin-bottom: 20px;
  margin-top: 100px;
}

.con-box2-footer {
  margin-left: 5%;
  width: 50%;
  margin-top: 30px;
  font-weight: bold;
  font-size: large;
  color: white;
}

.Foobtn {
  border-color: #fff !important;
  background: transparent !important;
  color: white !important;
  border-radius: 35px !important;
}

.Foobttn {
  top: 50%;
  align-items: center;
  width: 225px;
  z-index: 99;
  /* position: fixed; */
  left: 14%;
  margin-left: 5%;
  padding-bottom: 15px;
}

@media screen and (min-width: 601px) and (max-width: 769px) {
  .plane5 {
    margin-top: 200px;
    width: 100%;
    /* height: 1150px; */
    background-color: #1d1c23;
    /* transform: translate(10%); */
  }
}

.xyz {
  transform: rotate(270deg);
  width: 50px;
  text-align: right;
  /* position: relative; */
}

.qwerty {
  margin-bottom: 20px;
  --bs-btn-border-radius: 35px;
  --bs-btn-border-color: #b8b8b8;
  --bs-btn-bg: #ffffff;
  --bs-btn-color: #141619;
  min-width: 300px;
}

.qwertz {
  margin-bottom: 20px;
  --bs-btn-border-radius: 35px;
  --bs-btn-border-color: #b8b8b8;
  --bs-btn-bg: #ffffff;
  --bs-btn-color: #141619;
  min-width: 200px;
}

@media screen and (min-width: 601px) and (max-width: 850px) {
  .FooterNav {
    width: fit-content;
    /* position: relative; */
    /* bottom: -215px; */
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .ConUS {
    margin: auto;
    font-size: medium;
    zoom: 120%;
  }

  .FooterNav {
    width: fit-content;
    /* position: relative; */
    /* bottom: -215px; */
    top: 24%;
  }

  .t-all {
    text-align: left;
  }

  .UlFoot {
    display: inline-block;
    /* list-style: none; */
    /* align-items: center; */
    /* text-align: center; */
  }
  .foot {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
    cursor: pointer;
  }

  .plane5 {
    width: 100%;
    /* height: 1250px; */
    background-color: #1d1c23;
    /* transform: translate(10%); */
  }

  .copyright {
    color: white;
    /* position: relative; */
    /* bottom: -250px; */

    margin-right: 9%;
    padding-top: 20px;
    padding-left: 30px;
    font-size: 20px;
    top: 24%;
  }
  .fooHr {
    top: 10%;
  }
  .logocopy {
    bottom: 0px;
    top: 24%;
  }
  .con-box2-footer {
    width: 90%;
  }
}
